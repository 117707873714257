.uhcLogo {
  width: 130px;
  height: 40px;
  margin: 10px 12px;
}

.infoContent {
  padding: 80px 7% 20px 9%;
}

.title {
  font-size: 36.49px;
  color: #002677;
  font-weight: 600;
  font-family: "UHC Serif Headline";
  padding-bottom: 18px;
  letter-spacing: -0.5px;
  line-height: 48px;
}

.description {
  font-size: 18px;
  color: #323334;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 24px;
}

.handHoldingCardTabletImg {
  width: auto;
}

.signIn,
.registerNow {
  margin-right: 12px;
}

.signInTitle,
.registerTitle {
  font-size: 20.25px;
  color: #002677;
  font-weight: 700;
  padding-bottom: 9px;
}

.handHoldingCardImg {
  width: auto;
  display: none;
}

.handHoldingCardHalfImg {
  width: auto;
  display: none;
}

.footer {
  background-color: #00bed5;
  height: 256px;
  width: 100%;
  margin-top: 70px;
}

/* Responsiveness */
/* Desktop */
@media (min-width: 1248px) and (max-width: 1400px) {
  .infoContent {
    padding: 80px 16% 20px 2%;
  }
}

@media (min-width: 984px) and (max-width: 1247px) {
  .uhcLogo {
    margin: 10px 0;
  }

  .infoContent {
    padding: 80px 0 20px;
  }
}

@media (min-width: 1248px) {
  .handHoldingCardImg {
    display: block;
  }
}

@media (min-width: 984px) and (max-width: 1247px) {
  .handHoldingCardHalfImg {
    display: block;
  }
}

@media (min-width: 984px) {
  .footer {
    display: none;
  }
}

/* Tablet & Mobile */
@media (max-width: 983px) {
  .infoContent {
    padding: 50px 5% 20px 7%;
  }
}

/* Mobile */
@media (min-width: 280px) and (max-width: 767px) {
  .uhcLogo {
    margin: 10px 7.5%;
  }
}

@media (min-width: 280px) and (max-width: 743px) {
  .footer {
    height: 160px;
  }
}

@media (min-width: 280px) and (max-width: 463px) {
  .footer {
    margin-top: 30px;
  }
}
